.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    gap: 20px;
    /* min-height: 50vh; */
}

.containerTitle {
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
    gap: 4px;
}

.containerTitle span {
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}


.formField {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.formField a {
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.formField span {
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.formField textarea {
    border: none;
    height: 170px;
    font-family: "Montserrat";
    padding: 12px 20px;
    justify-content: center;
    border-radius: 12px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    align-items: center;
    resize: none;
}

.formField input {
    border-radius: 12px;
    border: none;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
    font-family: "Montserrat";
    padding: 12px 20px;
}

.dateField {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.dateField input {
    width: 100%;
    max-width: 256px;
}

.minOrderPriceField {
    flex-direction: row;
    align-items: center;
    gap: 20px;
}


.currencyFormField {
    width: 180px;
    display: flex;
    position: relative;
    z-index: 20;
    justify-content: flex-start;
}

.currencyBtnTrigger {
    width: 180px;
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    text-align: left;
    border: 1px solid #0E86F8;
    padding: 14px 10px;
    flex-shrink: 0;
    background-image: url("../../assets/icons8-стрелка-вниз-30.png");
    background-repeat: no-repeat;
    background-position:  center right 10px;
    background-size: 20px 20px;
}


.currencyDropDown {
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 180px;
    overflow: hidden;
    z-index: 20;
    border-radius: 10px;
    border: 1px solid #0E86F8;
}

.currencyDropDown div {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #0E86F8;
}

.currencyDropDownActiveItem {
    background-color: #a1cdec;
}

.currencyDropDown div:hover {
    background-color: #a1cdec;
    transition: 200ms;
}


.currencyDropDown div:last-child {
    border-bottom: 0px;
}

.checkboxArea {
    display: flex;
    width: 90%;
}

.saveBtn {
    display: flex;
    padding: 12px 40px;
    color: white;
    border-radius: 18px;
    cursor: pointer;
    font-family: "Montserrat";
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #0E86F8);
}

.infoImg {
    cursor: pointer;
    width: 18px;
    height: 18px;
}

.notificationBlock {
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 16%;
    transform: translateX(-50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px 10px;
    border-radius: 10px;
    background: var(--light-gray, #EEE);
    width: 200px !important;
    z-index: 3;
    overflow: hidden;
    color: var(--black, #282828);
    text-overflow: ellipsis;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.notificationBlock p {
    margin: 0;
    text-transform: none;
}

.saveBtn:hover {
    transition: 200ms;
    border: 1px solid var(--light-blue, #3CADFF);
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}


@media (min-width: 700px) {
    .container {
        padding: 40px 15%;
    }
}

@media (max-width: 600px) {
    .container {
        padding: 120px 15% 0px;
    }

    .saveBtn {
        margin: 20px 0px;
    }
}