.pageContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.contentWrapper {
    padding: 40px 20px;
}

.pageTitle {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}

@media (max-width: 600px) {
    .pageTitle {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .contentWrapper {
        padding: 20px 10px;
    }
}