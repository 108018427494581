:root {
    --primary-color: #007bff;
    --error-color: #dc3545;
    --success-color: #28a745;
    --text-color: #333;
    --text-secondary: #555;
    --bg-color: #fff;
    --overlay-color: rgba(0, 0, 0, 0.5);
}

.overlay {
    position: fixed;
    inset: 0;
    background: var(--overlay-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background: var(--bg-color);
    width: 90%;
    max-width: 450px;
    border-radius: 12px;
    padding: 24px 32px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    text-align: center;
    position: relative;
}

.modal h2 {
    margin-top: 0;
    font-size: 1.6rem;
    color: var(--text-color);
}

.modal p {
    margin: 12px 0;
    font-size: 1.1rem;
    color: var(--text-secondary);
    line-height: 1.4;
}

.cost {
    font-weight: bold;
    color: var(--primary-color);
    font-size: 1.25rem;
}

.additional {
    font-size: 1.2rem;
    color: var(--error-color);
    margin-top: 12px;
}

.decrease {
    font-size: 1.1rem;
    color: var(--success-color);
    margin-top: 12px;
}

.same {
    font-size: 1rem;
    color: #888;
}

.difference,
.newCost {
    font-weight: bold;
}

.buttonsRow {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    justify-content: center;
}

.confirmButton,
.cancelButton {
    flex: 1 1 auto;
    padding: 12px 18px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
    max-width: 200px;
}

.confirmButton {
    background: var(--primary-color);
    color: #fff;
}

.confirmButton:hover {
    background: #0056b3;
}

.cancelButton {
    background: #e0e0e0;
    color: #444;
}

.cancelButton:hover {
    background: #cfcfcf;
}

@media (max-width: 480px) {
    .modal {
        padding: 20px;
    }

    .modal h2 {
        font-size: 1.4rem;
    }

    .modal p {
        font-size: 1rem;
    }

    .cost {
        font-size: 1.1rem;
    }

    .buttonsRow {
        flex-direction: column;
        gap: 10px;
    }
}