.container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.dateCard {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-left: 5px solid #007bff;
}

.text {
    font-size: 1.2rem;
    color: #444;
    text-align: center;
    margin: 0;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.date {
    font-weight: 700;
    color: #007bff;
    margin-left: 8px;
}

@media (max-width: 480px) {
    .container {
        padding: 16px 20px;
        max-width: 90%;
    }

    .text {
        font-size: 1rem;
    }

    .date {
        font-size: 1.1rem;
    }
}