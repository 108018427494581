.container {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    color: #333;
}

.summarySection {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.summaryCard {
    width: 100%;
    max-width: 800px;
    background: linear-gradient(15deg, #007bff, #3cadff);
    color: #fff;
    padding: 20px 30px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summaryTitle {
    font-size: 1.4rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.priceRow {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.price {
    display: flex;
    align-items: flex-end;
    gap: 6px;
}

.priceValue {
    font-size: 2.8rem;
    font-weight: 700;
}

.priceCurrency {
    font-size: 1.6rem;
}

.crossedPrice {
    text-decoration: line-through;
    color: #999;
    font-size: 1.2em;
    margin-right: 10px;
}


.discountInfo {
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.3);
    padding: 4px 8px;
    border-radius: 4px;
}

.periodButtons {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.periodButton {
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
}

.periodButton:hover {
    background: rgba(255, 255, 255, 0.4);
}

.activePeriod {
    background: rgba(255, 255, 255, 0.6);
    color: #0056b3;
}

.connectButton {
    padding: 12px 24px;
    background: #fff;
    color: #007bff;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s;
}

.connectButton:hover {
    background: #e0e0e0;
}

.configSection {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.block {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.blockTitle {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 600;
    color: #444;
}

/* --- Слайдеры --- */
.slidersRow {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.sliderColumn {
    flex: 1;
    min-width: 200px;
}

.sliderLabel {
    font-size: 1rem;
    color: #555;
    margin-bottom: 8px;
    display: block;
}

.sliderGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sliderValue {
    font-size: 1.2rem;
    font-weight: 600;
    color: #007bff;
}

.rangeInput {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: #ddd;
    outline: none;
    cursor: pointer;
    transition: background 0.3s;
}

.rangeInput:hover {
    background: #ccc;
}

.rangeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.rangeInput::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

/* --- Дополнительные опции --- */
.optionsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 12px;
}

.optionCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fefefe;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px 20px;
    transition: box-shadow 0.3s;
}

.optionCard:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.optionLabel {
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    gap: 6px;
}

.labelText {
    white-space: normal;
}

.optionSub {
    font-size: 0.85rem;
    color: #666;
    margin-top: 4px;
}

/* --- Tooltip --- */
.tooltipIcon {
    position: relative;
    display: inline-block;
    background: #808080;
    color: #fff;
    border-radius: 50%;
    font-size: 0.75rem;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
}

.tooltipIcon:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 0.85rem;
    white-space: nowrap;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    z-index: 10;
}

.tooltipIcon:hover::before {
    content: '';
    position: absolute;
    bottom: 115%;
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid transparent;
    border-top-color: #333;
    z-index: 10;
}

/* --- Переключатель (switch) --- */
.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sliderSwitch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.sliderSwitch:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    transition: .4s;
    border-radius: 50%;
}

.switch input:checked+.sliderSwitch {
    background-color: #00b92d;
}

.switch input:checked+.sliderSwitch:before {
    transform: translateX(20px);
}

.baseFeaturesCard {
    background: #fff;
    border-radius: 12px;
    padding: 20px 30px;
    margin-top: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-left: 5px solid #007bff;
}

.baseFeaturesTitle {
    font-size: 1.4rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.baseFeaturesList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.baseFeaturesList li {
    position: relative;
    padding-left: 28px;
    font-size: 1rem;
    color: #555;
}

.baseFeaturesList li::before {
    content: '✔';
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1rem;
    color: #28a745;
    line-height: 1;
}

/* --- Адаптивность --- */
@media (max-width: 768px) {
    .slidersRow {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .labelText {
        max-width: 70%;
    }
}