@font-face {
    font-family: "Montserrat";
    src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

.shops {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
}

.shopsTitle {
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 22px;
    align-self: flex-start;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.shopsStorage {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
}

.shopAdd {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    width: 385px;
    border-radius: 12px;
    gap: 27px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.shopAdd:hover span {
    color: #3CADFF;
}

.shopAdd img {
    width: 40px;
    height: 40px;
}

.shopAdd:hover img {
    content: url("../../../assets/icon add-2.png");
}

.shopAdd span {
    color: var(--not-main-action, #747474);
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 700px) {
    .shopsTitle {
        text-align: center;
        width: 100%;
    }

    .shopsStorage {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .shopAdd {
        width: 334px;
        padding: 20px;
        margin: 0 auto;
        height: 20vh;
    }
}