@media (max-width: 700px) {
  .bodyBlock {
    width: auto !important;
  }

  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 40px;
  }

  .text {
    font-size: 14px;
  }

  .blockStorage {
    flex-direction: column;
    align-items: center;
  }

  .popupBtn {
    margin-top: 100px;
  }

  .statisticBlock {
    padding: 24px 12px;
    width: 284px;
  }

  .statisticSection {
    width: 100%;
    gap: 8px;
    font-size: 14px;
  }

  .shopsBtn span {
    width: 120px;
  }

  .statisticSectionShops:nth-child(2) {
    font-size: 14px;
  }

  .statisticSectionShops:nth-child(1) span:nth-child(1)::after {
    content: "";
    display: block;
    width: 285px;
    position: absolute;
    top: 95px;
    height: 1px;
    flex-shrink: 1;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .statisticSectionShops:nth-child(1) span:nth-child(2)::after {
    content: "";
    display: block;
    width: 285px;
    position: absolute;
    top: 130px;
    height: 1px;
    flex-shrink: 1;
    border-bottom: 1px solid #e1e1e1;
  }

  .statisticBtns {
    flex-direction: column;
  }

  .statisticBtns button {
    width: 285px;
  }

  .stats {
    width: 100%;
  }

  .stats span {
    font-size: 14px;
  }

  .stats span:nth-child(2) {
    font-size: 16px;
  }

  .statsItem span:nth-child(2) {
    font-size: 16px;
  }

  .tariffsInfoBlockTitle {
    flex-direction: column;
    font-size: 14px;
  }

  .miniTitle {
    font-weight: 600;
    font-size: 14px;
  }

  .btnBlock {
    width: 310px;
  }

  .btnsBlock {
    width: 100%;
  }

  .help {
    width: 310px;
  }


}

@media (min-width: 701px) {
  .container {
    width:  1420px;
    padding: 40px 120px;
  }

  .text {
    font-size: 20px;
  }

  .blockStorage {
    align-items: flex-start;
  }

  .statisticBlock {
    width: 346px;
    padding: 40px 56px;
  }

  .statisticSection {
    gap: 24px;
  }

  .shopsBtn {
    width: 183px;
  }

  .shopsBtn span {
    width: 118px;
  }

  .statisticSectionShops:nth-child(2) {
    font-size: 18px;
  }

  .statisticSectionShops:nth-child(1) span:nth-child(1)::after {
    content: "";
    display: block;
    width: 340px;
    position: absolute;
    top: 120px;
    height: 1px;
    flex-shrink: 1;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .statisticSectionShops:nth-child(1) span:nth-child(2)::after {
    content: "";
    display: block;
    width: 340px;
    position: absolute;
    top: 160px;
    height: 1px;
    flex-shrink: 1;
    border-bottom: 1px solid #e1e1e1;
  }

  .statisticBtns {
    width: 100%;
  }

  .statisticInfo span:nth-child(1) {
    color: var(--black, #282828);
    /* text */
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .stats {
    width: 345px;
  }

  .stats span {
    font-size: 16px;
  }

  .statsItem span:nth-child(2) {
    font-size: 18px;
  }

  .miniTitle {
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: "Montserrat" !important;
  }

  .tariffsInfoBlockLink {
    font-size: 16px;
  }

  .btnBlock {
    width: 286px;
  }

  .help {
    width: 286px;
  }
}

.container {
  width: 100%;
  max-width: 1420px;
  box-sizing: border-box;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupBtn {
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  cursor: pointer;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border: none;
  color: black;
  margin-bottom: 20px;
  align-self: flex-end;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

[role="tooltip"].popup-content {
  width: 500px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popupContent {
  display: flex;
  font-family: "Montserrat";
  justify-content: space-between;
}

.tooltip {
  border: 1px solid #eeeeee;
  width: 97%;
  height: 85px;
  font-size: 14px;
  overflow: hidden;
  padding: 12px;
  font-family: "Montserrat";
  border-radius: 10px;
}

.title {
  color: var(--black, #282828);
  font-family: "Montserrat";
  margin-bottom: 20px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
  text-transform: uppercase;
}

.text {
  align-self: flex-start;
  color: var(--black, #282828);
  margin-bottom: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 5px;
}

.blockStorage {
  display: flex;
  margin-top: 20px;
  align-self: center;
  gap: 20px;
  box-sizing: border-box;
}

.statisticBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  border-radius: 20px;
  justify-content: center;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
}



.statisticSection {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  /* border-bottom: 1px solid #E1E1E1; */
}

.statisticSectionShops {
  display: flex;
  flex-shrink: 0;
  gap: 16px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.statisticSectionShops:nth-child(2) {
  color: #3cadff;
  font-weight: 600;
  font-family: "Montserrat";
}

/* .statisticInfo {
    border-bottom: 1px solid #E1E1E1;
} */



.statisticSectionShops:nth-child(3) .shopsBtn:nth-child(2) {
  gap: 23px;
  margin-top: -7px;
}

.statisticSectionShops:nth-child(3) .shopsBtn:nth-child(1) {
  margin-top: -7px;
}

.shopsBtn {
  border: none;
  background: transparent;
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
  gap: 5px;
  font-family: "Montserrat";
}

.shopsBtn span {
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shopsBtn span::after {
  content: "\2192";
  font-size: 22px;
}

/* .shopsBtn span:nth-child(1)::after {
  margin-left: 10px;
} */

.shopsBtn span:hover::after {
  content: "\2192";
  font-size: 22px;
  margin-right: -8px;
  align-self: center;
  transition: 100ms;
}

.shopsBtn span:hover {
  color: #0e86f8;
  transition: 200ms;
}

.shopsBtn img {
  width: 14px;
  height: 18px;
}

.statisticInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
}

.popupContent .shopsBtn span {
  width: 190px;
}



.statisticBtns {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
}

.statisticBtns button {
  border-radius: 12px;
  border: 1px solid var(--light-blue, #3cadff);
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  background: white;
  cursor: pointer;
  padding: 8px 24px;
  font-size: 14px;
  color: #3cadff;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.statisticBtns button:hover {
  color: white;
  transition: 200ms;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);

  /* shadow-button */
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.stats {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.stats span {
  color: var(--black, #282828);

  /* text */
  font-family: "Montserrat";
  font-style: normal;
  flex-shrink: 0;
  font-weight: 400;
  line-height: normal;
}

.stats div {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  flex-shrink: 0;
}


.statsItem span:nth-child(2) {
  color: var(--blue, #0e86f8);
  text-align: center;

  /* ST2 */
  font-family: "Montserrat";

  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.statsItem::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 7px;
  flex-shrink: 1;
  border-bottom: 1px solid #e1e1e1;
}


.orderBtn {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  display: flex;
  color: #3cadff;
  padding: 14px 32px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.orderBtn:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.activeBtn {
  transition: 200ms;
  background: #0e86f8 !important;
  color: white !important;
}

.btnsBlock {
  flex-shrink: 0;
  align-self: flex-start;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnBlock {
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  height: 100px;
  color: #747474;
  font-family: "Montserrat";
  justify-content: center;
  border-radius: 18px;
  border: none;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
}

.btnBlock:hover {
  color: #3cadff;
  transition: 200ms;
}

.btnBlock div {
  width: 28px;
  height: 28px;
  background-image: url("../../../assets/icon\ add-3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.btnBlock:hover div {
  background-image: url("../../../assets/icon\ add-2.png");
}

.tariffsInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tariffsInfoBlockTitle {
  display: flex;
  gap: 12px;
}

.tariffsInfoBlockLink {
  color: #0e86f8;
  font-family: Montserrat;
  font-weight: 400;
  text-decoration: underline;
  word-wrap: break-word;
  cursor: pointer;
}

.tariffsInfoBlockExpirationTime {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.refTodoBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.help {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 215px;
  box-sizing: border-box;
  padding: 20px 25px;
  border-radius: 18px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
}

.help span {
  color: var(--not-main-action, #747474);
  text-align: center;
  margin-bottom: 10px;
  /* text-tablet */
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.help a ~ span {
  margin-top: 5px;
  margin-bottom: 5px !important;
}

.help a {
  font-size: 14px;
  color: #0e86f8;
  text-decoration: none;
}

.help a:hover {
  font-weight: 600;
  text-decoration: underline;
  flex-shrink: 0;
  font-size: 14.3px;
  transition: 600ms;
}
