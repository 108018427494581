.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 380px;
    max-width: 90%;
    padding: 40px 60px;
    border-radius: 16px;
    background: white;
}

.popupTitle {
    text-align: center;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
}

.popupContent {
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
}

.popupFooter {
    display: flex;
    align-items: center;
    gap: 20px;
}

.popupClose {
    position: absolute;
    width: 16px !important;
    width: 40px;
    height: 16px;
    top: 20px;
    right: 10px;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/Frame\ 872.png");
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.popupBtnPay {
    border-radius: 18px;
    background: var(--blue, #0e86f8);
    border: none;
    padding: 14px 24px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
    width: 130px;
}

.popupBtnPay:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
        0px 3px 6px 0px rgba(17, 17, 34, 0.18),
        0px 11px 11px 0px rgba(17, 17, 34, 0.15),
        0px 24px 14px 0px rgba(17, 17, 34, 0.09),
        0px 43px 17px 0px rgba(17, 17, 34, 0.03),
        0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.list {
    font-size: 12px;
}

.list input {
    cursor: pointer;
}

.popupBtnBack {
    border-radius: 18px;
    border: 1px solid var(--light-blue, #3cadff);
    background: var(--white, #fafafa);
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 16px;
    z-index: 3;
    position: relative;
    padding: 14px 24px;
    color: #3cadff;
    width: 130px;
}

.popupBtnBack:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
        0px 3px 6px 0px rgba(17, 17, 34, 0.18),
        0px 11px 11px 0px rgba(17, 17, 34, 0.15),
        0px 24px 14px 0px rgba(17, 17, 34, 0.09),
        0px 43px 17px 0px rgba(17, 17, 34, 0.03),
        0px 67px 19px 0px rgba(17, 17, 34, 0);
}