.card {
    display: flex;
    flex-direction: column;
    background: var(--white, #fafafa);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
    border-radius: 20px;
    padding: 24px;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
}

.cardTitle {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.formGroup label {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 6px;
}

.formGroup input {
    display: flex;
    padding: 12px 20px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 16px;
    border: none;
    font-family: "Montserrat";
    align-items: center;
    border-radius: 12px;
    background: var(--white, #fafafa);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
    gap: 10px;
}

.formGroup input:focus {
    border-color: #007bff;
    outline: none;
}

.inputGroup {
    display: flex;
    align-items: center;
    gap: 12px;
}

.inputGroup input {
    width: 100%;
}

.inputGroup button {
    width: 126px;
    height: 36px;
    border-radius: 10px;
}

.info {
    background-color: #e7f3fe;
    border: 1px solid #b3d7ff;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    margin-top: 15px;
}

.btnOutline {
    border-radius: 18px;
    border: 1px solid var(--blue, #0e86f8);
    background: var(--white, #fafafa);
    color: #0e86f8;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    width: 284px;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: center;
}


.btnOutline:hover {
    background: #0e86f8;
    color: #fafafa;
}

@media (max-width: 480px) {
    .card {
        padding: 16px;
    }

    .cardTitle {
        font-size: 1.1rem;
        margin-bottom: 16px;
    }

    .formGroup input {
        height: 36px;
        font-size: 0.9rem;
    }

    .btnOutline {
        height: 36px;
        font-size: 0.9rem;
    }
}