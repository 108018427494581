.container {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #444;
}

.cancelSubscriptionBtn {
    background: linear-gradient(135deg, #ff6b6b, #ff3b3b);
    color: #fff;
    padding: 14px 24px;
    font-size: 1.1rem;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cancelSubscriptionBtn:hover {
    background: linear-gradient(135deg, #ff5a5a, #ff2a2a);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.wizard {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;
    margin-top: 20px;
}

.step {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.step p {
    margin: 0;
    font-size: 1.1rem;
    color: #555;
    line-height: 1.5;
}

.buttonsRow {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
}

.keepButton,
.nextButton,
.prevButton,
.cancelButton {
    padding: 12px 22px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.3s, box-shadow 0.3s;
}

.keepButton {
    background: #6c757d;
    color: #fff;
}

.keepButton:hover {
    background: #5a6268;
}

.nextButton {
    background: #007bff;
    color: #fff;
}

.nextButton:hover {
    background: #0056b3;
}

.prevButton {
    background: #6c757d;
    color: #fff;
}

.prevButton:hover {
    background: #5a6268;
}

.cancelButton {
    background: #dc3545;
    color: #fff;
}

.cancelButton:hover {
    background: #c82333;
}

.nextButton:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    color: #444;
}

.checkboxLabel input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.checkboxLabel input[type="checkbox"]:checked {
    background-color: #007bff;
    border-color: #007bff;
}

.checkboxLabel input[type="checkbox"]:focus {
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

.select {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 40px 10px 12px;
    font-size: 1.1rem;
    color: #444;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    appearance: none;
    -webkit-appearance: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    background-image: none;
    cursor: pointer;
}

.select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    pointer-events: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #666;
}

.select:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
    outline: none;
}

@media (max-width: 600px) {
    .container {
        padding: 15px;
        margin: 10px;
        max-width: 90%;
    }

    .wizard {
        padding: 20px 20px;
    }

    .step h2 {
        font-size: 1.3rem;
    }

    .step p {
        font-size: 1rem;
    }

    .buttonsRow {
        flex-direction: column;
        align-items: stretch;
    }

    .nextButton,
    .prevButton,
    .cancelButton,
    .keepButton {
        width: 100%;
        text-align: center;
    }

    .checkboxLabel {
        font-size: 0.95rem;
    }

    .select {
        font-size: 1rem;
        padding: 8px 35px 8px 10px;
    }

    .select::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #666;
        right: 10px;
    }
}